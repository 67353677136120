<template>
  <div>问答总页Coding</div>
</template>

<script>
export default {
    name:'MainQA'
}
</script>

<style>

</style>